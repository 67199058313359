import React, { useContext } from 'react';
import { LayoutContext } from './layout/context/LayoutContext';
import { Prestacao } from './slidein_api';

export function isFormFieldValid<ObjectType>(name: keyof ObjectType, form) {
  return !!(form.touched[name] && form.errors[name]);
}

export function getFormErrorMessage<ObjectType>(name: keyof ObjectType, form) {
  return (
    isFormFieldValid<ObjectType>(name, form) && (
      <small className='p-error'>{form.errors[name]}</small>
    )
  );
}

export function MBLOGO() {
  const { layoutConfig } = useContext(LayoutContext);

  return (
    <svg
      id='svg2'
      xmlns='http://www.w3.org/2000/svg'
      fill='gray'
      viewBox='0 0 153.98 181.88'>
      <title>Logo_Multibanco</title>
      <g id='g20'>
        <path
          id='path22'
          fill='#3d73b9'
          className='cls-1'
          d='M83.48,153.58h63.06c14.39,0,14.54-15.3,13.07-22.85-.8-5.08-9.43-5-10.38,0v5.89a4.91,4.91,0,0,1-4.9,4.9H23.45a4.91,4.91,0,0,1-4.9-4.9v-5.89c-.95-5-9.58-5.08-10.38,0-1.47,7.55-1.32,22.85,13.07,22.85H83.48ZM38.91,7h97.22c6.83,0,12.42,5.92,12.42,13.15v6.28c0,8.88-12,8.84-12,.06V23.07a4,4,0,0,0-4-4H35.13a4,4,0,0,0-4,4v3.36c0,8.83-11.46,8.77-11.46.19V20.16C19.7,12.93,25.28,7,32.11,7Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g24'>
        <path
          id='path26'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          fillRule='evenodd'
          className='cls-2'
          d='M146.1,79.21a19.22,19.22,0,0,1,11.38,17.22c0,10.58-9.24,19.24-20.53,19.24H105.28a5.23,5.23,0,0,1-5.38-5V50.39a5.3,5.3,0,0,1,5.29-5.28h26.37a20.13,20.13,0,0,1,14.54,34.1m-23.63-4.9h10.05V74.2a9.61,9.61,0,0,0,8.12-9.45h0a9.59,9.59,0,0,0-9.56-9.56H110.83v50H136.5a9.88,9.88,0,1,0,0-19.75h-4v0H122.47a5.55,5.55,0,1,1,0-11.1'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g28'>
        <path
          id='path30'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          className='cls-3'
          d='M42.92,172.08a1.7,1.7,0,0,1,3.4,0v9.18h0a7.63,7.63,0,0,1-7.61,7.62h0a7.63,7.63,0,0,1-7.62-7.61h0v-9.18a1.7,1.7,0,1,1,3.39,0v9.18h0a4.27,4.27,0,0,0,4.24,4.23h0a4.25,4.25,0,0,0,4.23-4.24h0Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g32'>
        <path
          id='path34'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          className='cls-3'
          d='M59,185.49a1.7,1.7,0,1,1,0,3.4H54.28a6.37,6.37,0,0,1-6.37-6.37h0V172.08a1.69,1.69,0,1,1,3.38,0V182.5h0a3,3,0,0,0,3,3H59Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g36'>
        <path
          id='path38'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          className='cls-3'
          d='M94.87,109.66A6.36,6.36,0,1,1,82.23,111L77,63.09,58.59,109.94l0,0h0l0,.12,0,.1v0l0,.1,0,.06,0,.09,0,0,0,.1,0,0A6.12,6.12,0,0,1,57,112.33v0l-.1.08a5.75,5.75,0,0,1-1.1.78l0,0-.12.06h0l-.11.06,0,0-.07,0-.08,0-.07,0-.08,0-.06,0-.07,0-.06,0h0l-.06,0-.08,0,0,0a6.05,6.05,0,0,1-1.86.35h-.46a6.09,6.09,0,0,1-2.08-.43l-.09,0h0l-.09,0-.08,0-.06,0-.08,0-.07,0-.05,0-.11-.06h0l-.1-.06-.06,0a6,6,0,0,1-1-.67l0,0-.21-.19h0l-.16-.15L48,112h0l-.19-.21,0,0a6,6,0,0,1-.67-1l0-.06-.06-.1h0l-.06-.11,0,0,0-.07,0-.08,0-.06,0-.09,0-.08v0l0-.09L28.27,63.09,23.06,111a6.35,6.35,0,1,1-12.63-1.34l6.14-56.48v0h0a10.53,10.53,0,0,1,1.18-3.8,10.54,10.54,0,0,1,7.9-5.48h0l.34,0a10.87,10.87,0,0,1,1.61,0h0a10.23,10.23,0,0,1,1.92.26,10.43,10.43,0,0,1,7.39,6.38L52.65,90.32,68.36,50.41a10.45,10.45,0,0,1,9.31-6.64h0a10.87,10.87,0,0,1,1.61,0l.34,0h0a10.56,10.56,0,0,1,7.9,5.48,12.16,12.16,0,0,1,.77,1.84,10.36,10.36,0,0,1,.41,2h0v0Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g40'>
        <path
          id='path42'
          className='cls-3'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          d='M30.43,187a1.7,1.7,0,1,1-3.37.4l-1.45-12.23-5.19,12a1.7,1.7,0,0,1-2.23.89,1.67,1.67,0,0,1-.88-.89h0l-5.19-12-1.46,12.23a1.7,1.7,0,1,1-3.37-.4L9,172.8a2.73,2.73,0,0,1,1.49-2.11,2.52,2.52,0,0,1,.33-.15l.13,0a2.18,2.18,0,0,1,.5-.11h0a2.89,2.89,0,0,1,2.37.75,2.57,2.57,0,0,1,.34.39,2.8,2.8,0,0,1,.2.32,1.74,1.74,0,0,1,.1.19l4.4,10.2L23.26,172a2.8,2.8,0,0,1,2-1.61,4,4,0,0,1,.5-.07h.52a2.86,2.86,0,0,1,2.12,1.35h0a2.42,2.42,0,0,1,.23.49,1.59,1.59,0,0,1,.09.33,1.7,1.7,0,0,1,0,.22v0Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g44'>
        <path
          id='path46'
          className='cls-3'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          d='M66.4,187.19a1.7,1.7,0,1,1-3.4,0V173.78H58.17a1.7,1.7,0,0,1,0-3.4H71.23a1.7,1.7,0,0,1,0,3.4H66.4Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g48'>
        <path
          id='path50'
          className='cls-3'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          d='M77,187.19a1.7,1.7,0,1,1-3.4,0V172.08a1.7,1.7,0,0,1,3.4,0Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g52'>
        <path
          id='path54'
          className='cls-3'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          d='M111.81,187a1.7,1.7,0,0,1-3.37.41l-.6-4.8H102a1.7,1.7,0,1,1,0-3.39h5.46l-.16-1.25h0s0-.08,0-.12a6.77,6.77,0,0,0-.19-.87,5.69,5.69,0,0,0-.35-.89,3.86,3.86,0,0,0-3.44-2.29h0a4,4,0,0,0-.86.1,3.38,3.38,0,0,0-.76.29,4.75,4.75,0,0,0-2.36,3.78l-1.06,9.44a1.69,1.69,0,1,1-3.36-.37l1.06-9.44a8.12,8.12,0,0,1,4.19-6.44,7,7,0,0,1,1.5-.55,7.31,7.31,0,0,1,1.65-.19h0a7.15,7.15,0,0,1,6.46,4.17,9.64,9.64,0,0,1,.58,1.44,9.76,9.76,0,0,1,.29,1.39.4.4,0,0,1,0,.15v0Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g56'>
        <path
          id='path58'
          className='cls-3'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          d='M117,187.19a1.7,1.7,0,1,1-3.39,0v-14h0a1.37,1.37,0,0,1,0-.29,2.45,2.45,0,0,1,.13-.55h0l.14-.3h0a2.44,2.44,0,0,1,1.33-1.11l.23-.06.18,0h0l.4,0h.05a2.4,2.4,0,0,1,1.79.92l9,11.53V172.08a1.7,1.7,0,1,1,3.4,0v14a2.39,2.39,0,0,1-.89,1.85,2.16,2.16,0,0,1-.35.24,1.36,1.36,0,0,1-.21.1h0l-.14.06h0a2.39,2.39,0,0,1-1.82-.1l-.22-.11a2,2,0,0,1-.33-.26,2.27,2.27,0,0,1-.22-.22l-.06-.07h0l0,0L117,176Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g60'>
        <path
          id='path62'
          className='cls-3'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          d='M143.52,185.49a1.7,1.7,0,1,1,0,3.4h-5a6.69,6.69,0,0,1-6.69-6.69h0v-5.11h0a6.71,6.71,0,0,1,6.69-6.69h5a1.7,1.7,0,0,1,0,3.4h-5a3.33,3.33,0,0,0-2.34,1,3.28,3.28,0,0,0-1,2.33h0v5.11h0a3.33,3.33,0,0,0,3.31,3.31h5Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g64'>
        <path
          id='path66'
          className='cls-3'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          d='M153.35,173.77h0a4.62,4.62,0,0,0-3.21,1.28,4.16,4.16,0,0,0-1.31,3h0v3.17h0a4.15,4.15,0,0,0,1.3,3,4.62,4.62,0,0,0,3.21,1.28h0a4.62,4.62,0,0,0,3.21-1.28,4.16,4.16,0,0,0,1.31-3h0v-3.17h0a4.19,4.19,0,0,0-1.3-3,4.67,4.67,0,0,0-3.22-1.28m0-3.39h0a8,8,0,0,1,5.56,2.22,7.53,7.53,0,0,1,2.35,5.44h0v3.17h0a7.53,7.53,0,0,1-2.35,5.44,8,8,0,0,1-5.56,2.22h0a8,8,0,0,1-5.56-2.22,7.56,7.56,0,0,1-2.34-5.44h0v-3.17h0a7.56,7.56,0,0,1,2.34-5.44,8,8,0,0,1,5.56-2.22h0Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
      <g id='g68'>
        <path
          id='path70'
          className='cls-3'
          fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
          d='M81.81,173.78v11.71h6.56a2.13,2.13,0,0,0,2.13-2.14h0a2.12,2.12,0,0,0-.56-1.44l-.07-.06a2.09,2.09,0,0,0-1.5-.64H86.11a1.7,1.7,0,0,1,0-3.4h.77a2.09,2.09,0,0,0,1.28-.59h0a2,2,0,0,0,.6-1.43h0a2,2,0,0,0-2-2H81.81Zm-3.4,5.75v-7.38h0a1.64,1.64,0,0,1,.14-.67,2,2,0,0,1,.39-.59l0,0h0a1.74,1.74,0,0,1,1.12-.48h6.65a5.41,5.41,0,0,1,5.41,5.41h0a5.47,5.47,0,0,1-.83,2.88,5.44,5.44,0,0,1,1,.76l.11.12a5.5,5.5,0,0,1,1.51,3.79h0a5.53,5.53,0,0,1-5.52,5.52H80.11a1.7,1.7,0,0,1-1.7-1.7Z'
          transform='translate(-7.28 -7.01)'
        />
      </g>
    </svg>
  );
}

export function SlideinLogo() {
  const { layoutConfig } = useContext(LayoutContext);

  return (
    <svg
      width='150px'
      height='25px'
      viewBox='0 0 149.78555 25.519012'
      version='1.1'
      id='svg954'
      xmlns='http://www.w3.org/2000/svg'>
      <defs id='defs951'>
        <clipPath
          transform='matrix(2.8346458,0,0,-2.8346458,-393.46711,115.79758)'
          clipPathUnits='userSpaceOnUse'
          id='clipPath913'>
          <path
            d='M -46.927797,11.087199 H 250.07228 V 221.08875 H -46.927797 Z'
            id='path915'
            strokeWidth={0.352778}
          />
        </clipPath>
        <clipPath
          transform='matrix(2.8346458,0,0,-2.8346458,-436.16113,159.92218)'
          clipPathUnits='userSpaceOnUse'
          id='clipPath907'>
          <path
            d='M -46.927797,11.087199 H 250.07228 V 221.08875 H -46.927797 Z'
            id='path909'
            strokeWidth={0.352778}
          />
        </clipPath>
      </defs>
      <g
        id='layer1'
        transform='translate(-14.405661,-150.29283)'>
        <g
          id='g43'
          transform='matrix(0.35277777,0,0,-0.35277777,125.24632,157.0924)'
          clipPath='url(#clipPath913)'>
          <path
            d='M 0,0 V 18.788 H -49.519 -68.068 V 0 -9.36 -24.793 -31.117 c -0.178,-1.031 -0.26,-2.072 -0.26,-3.136 0,-1.082 0.082,-2.105 0.26,-3.139 v -0.016 c 1.501,-8.887 9.241,-15.655 18.549,-15.655 H 0 v 18.81 h -30.712 -18.807 v 9.46 H -33.26 -4.145 V -9.36 H -49.519 V 0 Z m -90.117,-9.422 h -20.341 -9.887 -2.468 c -1.953,-0.467 -3.468,-1.994 -3.936,-3.943 v -2.461 -4.404 -3.53 -4.388 -2.467 c 0.468,-1.954 1.983,-3.486 3.936,-3.937 h 2.468 9.887 6.63 13.711 v 14.111 z m 18.984,-27.211 v -0.024 -0.009 c 0,-9.048 -7.357,-16.397 -16.403,-16.397 h -0.017 -2.564 -36.632 c -9.082,0.01 -16.42,7.365 -16.42,16.406 0,0.725 0.048,1.411 0.129,2.105 h -0.146 v 25.13 h 0.146 c -0.081,0.678 -0.129,1.387 -0.129,2.081 0,9.063 7.338,16.419 16.42,16.419 h 36.632 v 9.71 h 18.984 v -9.71 -18.5 -25.13 z m -93.554,-16.147 h 18.743 v 71.568 h -18.743 z m -51.405,25.147 v 46.421 h -18.776 v -50.631 c -0.048,-0.547 -0.064,-1.115 -0.064,-1.66 0,-0.566 0.016,-1.114 0.064,-1.662 0.839,-9.71 8.871,-17.371 18.776,-17.615 h 0.436 48.114 v 18.414 h -41.84 c -3.711,0 -6.71,3.017 -6.71,6.733 m -41.536,18.338 h -8.016 -4.677 -24.808 v 28.083 c -11.211,0 -19.065,-10.66 -19.065,-21.66 0,-12.533 9.113,-21.633 18.162,-21.778 3.081,-0.049 9.485,0 9.485,0 h 5.08 17.372 c 3.436,0 6.226,-2.791 6.226,-6.24 v -3.614 h -24.226 -32.067 v -8.838 -0.162 c 0,0 0.016,-0.551 0.049,-0.832 0.419,-4.733 4.387,-8.444 9.225,-8.444 h 39.842 9.436 c 6.887,0 14.129,7.115 16.678,14.123 0.887,2.413 1.371,4.992 1.371,7.702 0,4.95 -1.614,9.548 -4.356,13.256 -2.999,4.083 -7.789,8.404 -15.711,8.404 m -11.935,9.197 h -22.372 v 18.886 h 5.855 39.325 c 3.21,0 5.806,-2.596 5.806,-5.775 V -0.098 Z'
            fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
            fillOpacity={1}
            fillRule={'nonzero'}
            stroke={'none'}
            id='path45'
          />
        </g>
        <g
          id='g47'
          transform='matrix(0.35277777,0,0,-0.35277777,140.30782,172.65858)'
          clipPath='url(#clipPath907)'>
          <path
            d='M 0,0 C 0,0.385 -0.302,0.702 -0.691,0.702 H -3.093 -3.837 -6.969 V -2.38 H -6.661 -0.286 0 Z M 3.487,0.879 V 0.702 -8.938 H 0 v 3.146 h -6.969 v -3.146 h -3.481 v 9.64 0.177 c 0,1.839 1.486,3.304 3.288,3.304 H -6.969 0 0.178 c 1.825,0 3.309,-1.465 3.309,-3.304 m -17.985,-1.273 c 0,0.594 -0.485,1.084 -1.095,1.084 h -2.278 -0.516 -3.114 v -3.07 h 0.308 5.293 0.307 c 0.107,0 0.205,0.016 0.302,0.032 0.453,0.13 0.793,0.556 0.793,1.047 v 0.599 z m 1.9,-4.55 1.727,-3.994 h -3.643 l -1.613,3.734 h -5.387 v -3.734 h -3.487 v 3.734 2.824 3.082 h 0.016 c -0.016,0.053 -0.016,0.113 -0.016,0.177 0,1.839 1.468,3.304 3.308,3.304 h 0.179 6.902 0.098 c 1.77,-0.048 3.222,-1.514 3.222,-3.304 v -3.734 c 0,-0.929 -0.545,-1.701 -1.306,-2.089 m -20.467,9.127 h -6.242 V 0.702 h 0.985 4.177 c 0.594,0 1.08,-0.502 1.08,-1.096 v -4.162 -4.382 h 3.468 v 9.64 h 3.999 v 3.481 h -3.999 z m 72.196,-9.521 c -0.529,0 -0.966,0.442 -0.966,0.966 v 2.154 6.401 h -3.469 v -9.521 -0.189 c 0,-1.814 1.451,-3.288 3.275,-3.288 h 0.194 8.874 v 3.477 H 41.281 Z M 21.552,4.183 v -3.481 -6.04 -0.189 c 0,-1.814 1.468,-3.288 3.287,-3.288 h 0.178 8.906 v 3.477 h -5.775 -3.131 v 1.546 h 2.661 5.582 v 2.822 h -8.243 v 1.672 h 8.906 V 4.183 H 25.017 Z M 11.712,-5.219 6.904,4.183 H 2.936 L 9.662,-8.938 h 0.145 3.827 0.14 l 6.715,13.121 h -3.956 z m 55.989,49.92 c 0,10.326 -8.372,18.698 -18.698,18.698 H 48.987 1.582 -17.113 V 9.91 H 1.582 v 34.791 h 28.714 11.853 c 3.773,0 6.854,-3.079 6.854,-6.87 V 26.01 -8.938 H 67.701 Z M -20.597,9.91 h -18.71 v 53.489 h 18.71 z'
            fill={'#60c44c'}
            fillOpacity={1}
            fillRule={'nonzero'}
            stroke={'none'}
            id='path49'
          />
        </g>
      </g>
    </svg>
  );
}

export function TiktokLogo() {
  const { layoutConfig } = useContext(LayoutContext);
  return (
    <svg
      fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
      width='30px'
      height='30px'
      viewBox='0 0 512 512'
      id='icons'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z' />
    </svg>
  );
}

export function InstaLogo() {
  const { layoutConfig } = useContext(LayoutContext);

  return (
    <svg
      fill={layoutConfig.colorScheme === 'light' ? 'black' : 'white'}
      width='30px'
      height='30px'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M20.445 5h-8.891A6.559 6.559 0 0 0 5 11.554v8.891A6.559 6.559 0 0 0 11.554 27h8.891a6.56 6.56 0 0 0 6.554-6.555v-8.891A6.557 6.557 0 0 0 20.445 5zm4.342 15.445a4.343 4.343 0 0 1-4.342 4.342h-8.891a4.341 4.341 0 0 1-4.341-4.342v-8.891a4.34 4.34 0 0 1 4.341-4.341h8.891a4.342 4.342 0 0 1 4.341 4.341l.001 8.891z' />
      <path d='M16 10.312c-3.138 0-5.688 2.551-5.688 5.688s2.551 5.688 5.688 5.688 5.688-2.551 5.688-5.688-2.55-5.688-5.688-5.688zm0 9.163a3.475 3.475 0 1 1-.001-6.95 3.475 3.475 0 0 1 .001 6.95zM21.7 8.991a1.363 1.363 0 1 1-1.364 1.364c0-.752.51-1.364 1.364-1.364z' />
    </svg>
  );
}

export type CalculatedPrestacao = {
  data: string;
  valor: number;
  valor_str: string;
  total_pago: number;
  f_pagar: boolean;
};

function fixp(nn) {
  return Math.round(nn * 100) / 100;
}
export function calculate_prestas(
  prestas: Prestacao[],
  valor_total: number,
  valor_seguro: number,
  valor_presta_minimo: number,
  valor_devido1: number,
  valor_custos_operacao: number
) {
  const ret_prestas: CalculatedPrestacao[] = [];
  let flag_next = false;
  let falta_pagar = valor_total-valor_custos_operacao;
  const valor_devido = Math.max(valor_devido1, 0);
  const pag_val = fixp(valor_total - valor_devido);

  prestas.forEach((pp, i) => {
    const pp_valor = pp.valor || 0;
    let valor = pp_valor;
    let valor_str = `${valor}€`;
    if (i == 0 && valor_seguro > 0) {
      valor = fixp(pp_valor + valor_seguro);
      valor_str = `${pp_valor} + ${valor_seguro} (seguro) = ${valor}€`;
    }
    if (fixp(falta_pagar - valor) < valor_presta_minimo || pp.ajuste_de_contas) {
      valor = falta_pagar;
      valor_str = `${valor}€ (Restante)`;
    }

    if (valor > valor_devido && fixp(valor_total - falta_pagar) > pag_val) {
      valor = valor_devido;
      valor_str = `${valor_total} - ${fixp(valor_total - valor_devido)} = ${valor}€`;
    }
    falta_pagar = fixp(falta_pagar - valor);
    let ff = false;
    if (!flag_next && fixp(valor_total - falta_pagar) > pag_val) {
      flag_next = true;
      ff = true;
    }

    if (valor > 0)
      ret_prestas.push({
        data: pp.data_limite,
        valor_str: valor_str,
        valor: valor,
        total_pago: fixp(valor_total - falta_pagar),
        f_pagar: ff,
      });
  });

  if (ret_prestas.length > 0) {
    return ret_prestas;
  } else {
    return [
      {
        data: null,
        valor_str: `${valor_total}€`,
        valor: valor_total,
        total_pago: valor_total,
        f_pagar: true,
      },
    ];
  }
}
