import React, { useContext, useEffect, useState } from 'react';
import {
  Actividade,
  ApiError,
  GrupoAderirInfo,
  Pacote,
  Seguro,
  Suplemento,
  TamanhoTshirtEnum,
  UtilizadorService,
  ViagemComprasRequest,
} from '../slidein_api';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ListBox } from 'primereact/listbox';
import { Checkbox } from 'primereact/checkbox';
import { useAuth } from '../user-auth/SlideInAuthContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import FullPageLoader from '../layout/FullPageLoader';
import dayjs from 'dayjs';
import { PrimeIcons } from 'primereact/api';
import { MessageContext } from '../layout/context/MessageContext';
import { Image } from 'primereact/image';
import { Dropdown } from 'primereact/dropdown';
import { calculate_prestas, CalculatedPrestacao } from '../helpers';
import { InputNumber } from 'primereact/inputnumber';

export function ViagemComprar() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [grupoCodigo, setGrupoCodigo] = useState(() => {
    return searchParams.get('codgrupo');
  });
  const [tempCodigo, setTempCodigo] = useState('');

  const [msg, setMsg] = useState('');

  const { msgError, toastError } = useContext(MessageContext);
  const [grupoInfo, setGrupoInfo] = useState<GrupoAderirInfo>();

  const [pacote, setPacote] = useState<Pacote>(undefined);
  const [seguro, setSeguro] = useState<Seguro>(undefined);
  const [actividades, setActividades] = useState<Actividade[]>([]);
  const [suplementos, setSuplementos] = useState<Suplemento[]>([]);
  const [aceitouCond, setAceitouCond] = useState<boolean>(false);
  const [aceitouCondSeguro, setAceitouCondSeguro] = useState<boolean>(false);
  const [tshirt, setTshirt] = useState<TamanhoTshirtEnum>(null);
  const [peso, setPeso] = useState<number>(null);
  const [altura, setAltura] = useState<number>(null);
  const [calcado, setCalcado] = useState<number>(null);
  const [total, setTotal] = useState<number>(-1);
  const [calcPrestas, setCalcPrestas] = useState<CalculatedPrestacao[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [errorCodigo, setErrorCodigo] = useState<string>();

  useEffect(() => {
    if (!grupoInfo || !pacote) {
      setTotal(-1);
      setCalcPrestas([]);
      return;
    }
    let temp = 0;
    if (pacote) {
      temp += pacote.valor;
    }

    if (seguro) {
      temp += seguro.valor;
    }
    temp += actividades.reduce<number>((acc: number, curr) => {
      return acc + curr.valor;
    }, 0);
    temp += suplementos.reduce<number>((acc: number, curr) => {
      return acc + curr.valor;
    }, 0);
    setTotal(temp);
    setCalcPrestas(
      calculate_prestas(
        grupoInfo.prestacoes,
        temp,
        seguro ? seguro.valor : 0,
        grupoInfo.valor_presta_ref_min,
        temp,
        0
      )
    );
  }, [pacote, seguro, actividades, suplementos, grupoInfo]);

  useEffect(() => {
    if (!aceitouCond || !pacote || (!seguro.base && !aceitouCondSeguro)) {
      setMsg('Falta aceitar as Condições Gerais de Venda');
    } else if (grupoInfo.campanha.pedir_tshirt && tshirt === null) {
      setMsg('Falta selecionar o teu tamanho de T-Shirt');
    } else if (grupoInfo.campanha.pedir_peso && peso === null) {
      setMsg('Falta indicar o teu peso');
    } else if (grupoInfo.campanha.pedir_altura && altura === null) {
      setMsg('Falta indicar a tua altura');
    } else if (grupoInfo.campanha.pedir_calcado && calcado === null) {
      setMsg('Falta selecionar o teu tamanho de calçado');
    } else {
      setMsg('');
    }
  }, [aceitouCond, tshirt, peso, altura, calcado, aceitouCondSeguro]);

  function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    if (grupoCodigo) {
      setLoading(true);
      UtilizadorService.utilizadorGrupoInfoAderirRetrieve({ codigo: grupoCodigo }).then(
        (value) => {
          setGrupoInfo(value);
          if (value.pacotes.length === 1) {
            setPacote(value.pacotes[0]);
          }
          const base = value.seguros.filter((seg) => {
            return seg.base;
          });
          if (base.length == 1) {
            setSeguro(base[0]);
          }
          setSuplementos([...value.suplementos.filter((sup: Suplemento) => sup.obrigatorio)]);
          setLoading(false);
        },
        (reason: ApiError) => {
          setLoading(false);
          if (reason.status === 404) {
            setErrorCodigo('O código fornecido não é valido, revê o código inserido!');
            setSearchParams({});
            setGrupoCodigo(undefined);
          }
          if (reason.status === 403) {
            if (reason.body.code === 'JAESTANACAMPANHA') {
              navigate('/aminha_viagem/' + reason.body.viagemID);
              //setErrorCodigo('Já estás inscrito nesta viagem/grupo, uma inscrição por utilizador!');
            }
            if (reason.body.code === 'CAMPANHAFECHADA') {
              sleep(1900).then(() => {
                navigate('/');
              });
              setErrorCodigo('As incrições na viagem estão fechadas!');
            }
            if (reason.body.code === 'GRUPOFECHADO') {
              sleep(1900).then(() => {
                navigate('/');
              });
              setErrorCodigo('As inscrições no grupo estão fechadas!');
            }
          }
        }
      );
    } else {
      setLoading(false);
    }
  }, [grupoCodigo, navigate, searchParams]);

  useEffect(() => {
    if (errorCodigo) {
      toastError('Não foi possível continuar.', errorCodigo, true);
    }
  }, [errorCodigo]);

  const novaViagemDialog = (
    <>
      <Card className={'max-w-full sm:max-w-min sm:min-w-max m-auto px-4'}>
        <h1>Olá,</h1>
        <h3>Não sabemos com qual grupo vais viajar.</h3>
        <h4>Sabes o código do teu grupo? </h4>
        <div className={'flex flex-row flex-wrap gap-2'}>
          <InputText
            value={tempCodigo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTempCodigo(e.target.value)
            }></InputText>

          <Button
            label={'Continuar'}
            outlined
            icon={PrimeIcons.ARROW_RIGHT}
            iconPos='right'
            onClick={() => {
              setErrorCodigo('');
              setGrupoInfo(null);
              setGrupoCodigo(tempCodigo);
              setSearchParams({ codgrupo: tempCodigo });
            }}></Button>
        </div>
        <div className={' mt-1 font-italic text-lg'}>
          Se te foi enviado um link podes também seguir esse link
        </div>
        <h4>Não tens grupo?</h4>
        <div className={'text-lg'}>Contacta-nos através do email:</div>
        <a
          href={
            'mailto:' +
            process.env.REACT_APP_EMAIL_SUPORTE +
            '?subject = Dificuldades Login&body = ...'
          }>
          {process.env.REACT_APP_EMAIL_SUPORTE}
        </a>
      </Card>
    </>
  );

  const pacoteTemplate = (pac: Pacote) => (
    <div
      className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}
      onClick={undefined}>
      <div className='flex flex-column'>
        <h3>{pac.titulo_cliente}</h3>
        <p>{pac.descricao || ''}</p>
        <div className={'flex flex-row gap-2'}>
          {pac.docs.map((doc) => (
            <Link
              onClick={() => {
                window.open(doc.file);
              }}
              className={'max-w-max'}
              key={doc.nome}
              download
              target='_self'
              to={doc.file}>
              🔗 {doc.nome}
            </Link>
          ))}
        </div>
      </div>
      <div
        className={'flex sm:flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
        <div className={'text-3xl font-medium'}>{pac.valor}€</div>
        {pac.is_disponivel ? (
          <>
            <Checkbox
              className={'ml-2 sm:ml-4 big'}
              checked={pacote && pac.id === pacote.id}></Checkbox>
            {pac.is_disponivel}
          </>
        ) : (
          <div
            className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
            <div className={'text-2xl font-medium'}>Esgotado!</div>
          </div>
        )}
      </div>
    </div>
  );
  const seguroTemplate = (seg: Seguro) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}>
      <div className='flex flex-column'>
        <h3>{seg.titulo_cliente}</h3>
        <p>{seg.descricao || ''}</p>
        <div className={'flex flex-row gap-2'}>
          {seg.docs.map((doc) => (
            <Link
              className={'max-w-max'}
              onClick={() => {
                window.open(doc.file);
              }}
              target={'_blank'}
              download
              key={doc.nome}
              to={doc.file}>
              🔗 {doc.nome}
            </Link>
          ))}
        </div>
      </div>
      {!seg.is_disponivel ? (
        <div className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-2xl font-medium'}>Esgotado!</div>
        </div>
      ) : seg.inscricao_previa ? (
        <div className={'flex flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-3xl font-medium'}>+{seg.valor}€</div>
          <div className={'sm:text-xl font-medium'}>{seg.msg_metodo_inscricao}</div>
        </div>
      ) : (
        <div
          className={'flex sm:flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-3xl font-medium'}>+{seg.valor}€</div>
          <Checkbox
            className={'ml-2 sm:ml-4 big'}
            checked={seguro && seg.id === seguro.id}></Checkbox>
        </div>
      )}
    </div>
  );
  const actividadeTemplate = (act: Actividade) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}>
      <div className='flex flex-column'>
        <h3>{act.titulo_cliente}</h3>
        <p>{act.descricao || ''}</p>
        <div className={'flex flex-row gap-2'}>
          {act.docs.map((doc) => (
            <Link
              className={'max-w-max'}
              onClick={() => {
                window.open(doc.file);
              }}
              target={'_blank'}
              download
              key={doc.nome}
              to={doc.file}>
              🔗 {doc.nome}
            </Link>
          ))}
        </div>
      </div>

      {!act.is_disponivel ? (
        <div className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-2xl font-medium'}>Esgotado!</div>
        </div>
      ) : act.inscricao_previa ? (
        <div className={'flex flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-3xl font-medium'}>+{act.valor}€</div>
          <div className={'sm:text-xl font-medium'}>{act.msg_metodo_inscricao}</div>
        </div>
      ) : act.brevemente ? (
        <div
          className={'flex sm:flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-3xl font-medium'}>Brevemente</div>
        </div>
      ) : (
        <div
          className={'flex sm:flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-3xl font-medium'}>+{act.valor}€</div>
          <Checkbox
            className={'ml-2 sm:ml-4 big'}
            checked={actividades.find((e) => e.id === act.id) !== undefined}></Checkbox>
        </div>
      )}
    </div>
  );
  const suplementoTemplate = (sup: Suplemento) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between'}>
      <div className='flex flex-column'>
        <h3>{sup.titulo_cliente}</h3>
        <p>{sup.descricao || ''}</p>
        <div className={'flex flex-row gap-2'}>
          {sup.docs.map((doc) => (
            <Link
              className={'max-w-max'}
              onClick={() => {
                window.open(doc.file);
              }}
              target={'_blank'}
              download
              key={doc.nome}
              to={doc.file}>
              🔗 {doc.nome}
            </Link>
          ))}
        </div>
      </div>

      {!sup.is_disponivel ? (
        <div className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-2xl font-medium'}>Esgotado!</div>
        </div>
      ) : sup.inscricao_previa ? (
        <div className={'flex flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-3xl font-medium'}>+{sup.valor}€</div>
          <div className={'sm:text-xl font-medium'}>{sup.msg_metodo_inscricao}</div>
        </div>
      ) : sup.brevemente ? (
        <div
          className={'flex sm:flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-3xl font-medium'}>Brevemente</div>
        </div>
      ) : (
        <div
          className={'flex sm:flex-column sm:gap-2 justify-content-around align-items-end ml-auto'}>
          <div className={'text-3xl font-medium'}>+{sup.valor}€</div>
          <Checkbox
            className={'ml-2 sm:ml-4 big'}
            disabled={sup.obrigatorio}
            checked={suplementos.find((e) => e.id === sup.id) !== undefined}></Checkbox>
        </div>
      )}
    </div>
  );

  const submeterCompra = () => {
    setLoading(true);
    const compra: ViagemComprasRequest = {
      cliente: user.id,
      compras_actividade: actividades.map((e) => {
        return {
          produto: e.id,
          viagem: null,
        };
      }),
      tamanho_tshirt: tshirt,
      peso: peso,
      altura: altura,
      calcado: calcado,
      compras_suplemento: suplementos.map((e) => {
        return {
          produto: e.id,
          viagem: null,
        };
      }),
      compras_outro: [],
      aceitou_condicoes: aceitouCond,
      grupo: grupoCodigo,
      compra_pacote: { produto: pacote.id, viagem: null },
      compra_seguro: {
        produto: seguro ? seguro.id : null,
        viagem: null,
        aceitou_condicoes: aceitouCondSeguro || seguro.base,
      },
    };
    UtilizadorService.utilizadorComprarCreate({ requestBody: compra }).then(
      (value) => {
        navigate('/aminha_viagem/' + value.id + '?obg=1');
      },
      (reason) => {
        if (reason.status === 403) {
          if (reason.body.code === 'JAESTANACAMPANHA') {
            sleep(1900).then(() => {
              navigate('/aminha_viagem/' + reason.body.viagemID);
            });
            setErrorCodigo('Já estás inscrito nesta viagem/grupo, uma inscrição por utilizador!');
          }
          if (reason.body.code === 'ESGOTADO') {
            sleep(1900).then(() => {
              location.reload();
            });
            setErrorCodigo(reason.body.msg);
          }
          if (reason.body.code === 'CAMPANHAFECHADA') {
            sleep(1900).then(() => {
              navigate('/');
            });
            setErrorCodigo('As inscrições na campanha estão fechadas!');
          }
          if (reason.body.code === 'GRUPOFECHADO') {
            sleep(1900).then(() => {
              navigate('/');
            });
            setErrorCodigo('As inscrições no grupo estão fechadas!');
          }
        } else {
          msgError(JSON.stringify(reason.body, null, 2));
        }
      }
    );
  };

  if (loading) {
    return <FullPageLoader />;
  }
  return (
    <>
      {!grupoInfo && novaViagemDialog}
      {grupoInfo && (
        <div className={'max-w-full sm:max-w-min sm:min-w-full m-auto'}>
          <div className='flex flex-column justify-content-center'>
            <h2>Configura a tua viagem </h2>
          </div>
          <div className={'grid'}>
            <div className='col-12 md:col-8'>
              <Card className={'m-auto'}>
                <h3> Pacote Viagem /Alojamento </h3>
                <ListBox
                  dataKey={'id'}
                  className={'produtos_style'}
                  itemTemplate={pacoteTemplate}
                  metaKeySelection={false}
                  multiple={false}
                  optionDisabled={(option) => {
                    return !option.is_disponivel;
                  }}
                  options={grupoInfo.pacotes.sort((a, b) =>
                    a.ordem_de_aparencia > b.ordem_de_aparencia ? 1 : a.id < b.id ? 1 : -1
                  )}
                  value={pacote}
                  onChange={(event) => {
                    if (grupoInfo.pacotes.length > 1) {
                      setPacote(event.value);
                    }
                  }}
                />
                {grupoInfo.seguros.length >= 1 && pacote && (
                  <div className={'mt-4'}>
                    <h3>Seguros</h3>
                    <ListBox
                      dataKey={'id'}
                      className={'produtos_style'}
                      itemTemplate={seguroTemplate}
                      metaKeySelection={false}
                      multiple={false}
                      value={seguro}
                      optionDisabled={(option) => {
                        return !option.is_disponivel;
                      }}
                      options={grupoInfo.seguros.sort((a, b) =>
                        a.ordem_de_aparencia > b.ordem_de_aparencia ? 1 : a.id < b.id ? 1 : -1
                      )}
                      onChange={(event) => {
                        if (event.value !== null) {
                          if (!event.value.inscricao_previa) {
                            setAceitouCondSeguro(false);
                            setSeguro(event.value);
                          }
                        }
                      }}
                    />
                  </div>
                )}
                {grupoInfo.actividades.length >= 1 && pacote && (
                  <div className={'mt-4'}>
                    <h3>Opcionais</h3>
                    <ListBox
                      dataKey={'id'}
                      value={actividades}
                      className={'produtos_style'}
                      itemTemplate={actividadeTemplate}
                      metaKeySelection={false}
                      multiple={true}
                      optionDisabled={(option) => {
                        return (
                          !option.is_disponivel || option.brevemente || option.inscricao_previa
                        );
                      }}
                      options={grupoInfo.actividades.sort((a, b) =>
                        a.ordem_de_aparencia > b.ordem_de_aparencia ? 1 : a.id < b.id ? 1 : -1
                      )}
                      onChange={(event) => {
                        setActividades(
                          event.value.filter(
                            (act: Actividade) => !act.brevemente && !act.inscricao_previa
                          )
                        );
                      }}
                    />
                  </div>
                )}
                {grupoInfo.suplementos.length >= 1 && pacote && (
                  <div className={'mt-4'}>
                    <h3>Suplementos</h3>
                    <ListBox
                      dataKey={'id'}
                      value={suplementos}
                      className={'produtos_style'}
                      itemTemplate={suplementoTemplate}
                      metaKeySelection={false}
                      multiple={true}
                      optionDisabled={(option) => {
                        return (
                          !option.is_disponivel || option.brevemente || option.inscricao_previa
                        );
                      }}
                      options={grupoInfo.suplementos.sort((a, b) =>
                        a.ordem_de_aparencia > b.ordem_de_aparencia ? 1 : a.id < b.id ? 1 : -1
                      )}
                      onChange={(event) => {
                        setSuplementos([
                          ...event.value.filter(
                            (sup: Suplemento) =>
                              !sup.brevemente && !sup.inscricao_previa && !sup.obrigatorio
                          ),
                          ...suplementos.filter((sup: Suplemento) => sup.obrigatorio),
                        ]);
                      }}
                    />
                  </div>
                )}
              </Card>
              {grupoInfo.campanha.nao_incluido != '' && (
                <Card className={'m-auto'}>
                  <h3> Não Inclui :</h3>
                  <p>{grupoInfo.campanha.nao_incluido}</p>
                </Card>
              )}
            </div>
            <div className='col-12 md:col-4 '>
              <Card
                className={'m-auto mt-1 sticky'}
                style={{ top: '5%' }}>
                <div className='flex flex-column gap-2 '>
                  <div className={'flex flex-row gap-4'}>
                    <div className={'flex flex-shrink-1'}>
                      <Image
                        width={'100px'}
                        src={grupoInfo.campanha.marca.logo}
                        imageStyle={{
                          background: 'whitesmoke',
                          padding: '6px',
                          borderRadius: '5%',
                        }}
                      />
                    </div>
                    <div className={'flex flex-column flex-grow-1'}>
                      <div className={'font-italic'}>
                        <i className={PrimeIcons.PHONE + ' mx-1'} />
                        {grupoInfo.campanha.marca.telefone}
                      </div>
                      <div className={'font-italic'}>
                        <i className={PrimeIcons.AT + ' mx-1'} />
                        {grupoInfo.campanha.marca.email}
                      </div>
                    </div>
                  </div>
                  <Divider></Divider>
                  <div className={'text-3xl'}>{grupoInfo.campanha.nome}</div>
                  <div className={'text-2xl my-2'}>Destino: {grupoInfo.campanha.destino}</div>
                  <div className={'text-xl my-2'}>
                    Datas: {grupoInfo.campanha.data_inicio_viagem}&nbsp;a&nbsp;
                    {grupoInfo.campanha.data_fim_viagem} ({grupoInfo.campanha.nr_noites} noites)
                  </div>
                  <Divider></Divider>
                  <div className={'text-xl'}>{grupoInfo.instituicao}</div>
                  <div className={'mt-1 text-lg'}>Código do teu grupo: {grupoCodigo}</div>

                  {seguro && !seguro.base && (
                    <>
                      <Divider></Divider>
                      <Card
                        className={'my-1 border-round border-1 border-primary'}
                        title={'Seguro: ' + seguro.titulo_cliente}>
                        <p className={'font-bold text-xl'}>Garantias e Capitais Seguros:</p>
                        {seguro.docs.map((doc) => (
                          <Link
                            className={'m-1'}
                            key={doc.nome}
                            to={doc.file}
                            target={'_blank'}
                            download>
                            🔗 {doc.nome}
                          </Link>
                        ))}
                        <Divider></Divider>

                        <div className={'text-xl font-bold mb-2'}>Pagamento</div>
                        <p className='m-0'>
                          Por motivos contratuais, o pagamento do seguro tem que ser efetuado em
                          conjunto com a primeira prestação da viagem.
                        </p>
                        <div className='flex flex-row mt-3 justify-content-end'>
                          <Checkbox
                            id={'aceitou_condicoes_seguro'}
                            checked={aceitouCondSeguro}
                            onChange={(e) => {
                              setAceitouCondSeguro(e.target.checked);
                            }}></Checkbox>
                          <label
                            htmlFor='ingredient1'
                            className='ml-2'>
                            LI NA ÍNTEGRA E ACEITO AS GARANTIAS DO SEGURO
                          </label>
                        </div>
                      </Card>
                    </>
                  )}
                  <Divider></Divider>
                  <div className={'text-2xl'}>Preço Total: {total === -1 ? '-' : total + '€'}</div>

                  {grupoInfo.campanha.mostrar_prestas_clientes && (
                    <>
                      <Divider></Divider>
                      <p className={'font-bold'}>Plano de Prestações</p>
                      <DataTable
                        dataKey={'data'}
                        id={'prestas_table'}
                        loading={loading}
                        lazy={true}
                        value={calcPrestas}
                        size={'small'}
                        showGridlines
                        stripedRows>
                        <Column
                          header={'Data Limite'}
                          body={(presta: CalculatedPrestacao) => {
                            return dayjs(presta.data).locale('pt').format('YYYY-MM-DD');
                          }}
                        />
                        <Column
                          header={'Valor da Prestação'}
                          field={'valor_str'}
                        />
                      </DataTable>
                    </>
                  )}
                  {(grupoInfo.campanha.pedir_tshirt ||
                    grupoInfo.campanha.pedir_peso ||
                    grupoInfo.campanha.pedir_altura ||
                    grupoInfo.campanha.pedir_calcado) && (
                    <p className={'text-xl font-bold'}>Informações Logísticas/Promocionais</p>
                  )}
                  {grupoInfo.campanha.pedir_tshirt && (
                    <div className='flex flex-column gap-2 '>
                      <div className={'font-bold'}>Tamanho T-shirt:</div>
                      <Dropdown
                        optionLabel='label'
                        optionValue='value'
                        className={'w-8rem'}
                        value={tshirt}
                        options={Object.keys(TamanhoTshirtEnum).map((x) => {
                          return {
                            label:
                              TamanhoTshirtEnum[x as keyof typeof TamanhoTshirtEnum].toUpperCase(),
                            value: TamanhoTshirtEnum[x as keyof typeof TamanhoTshirtEnum],
                          };
                        })}
                        placeholder='Escolher'
                        onChange={(event) => {
                          setTshirt(event.value);
                        }}
                      />
                    </div>
                  )}
                  {grupoInfo.campanha.pedir_peso && (
                    <div className='flex flex-column gap-2'>
                      <div className={'font-bold'}>Peso(Kg):</div>
                      <InputNumber
                        value={peso}
                        className={'w-4rem'}
                        onValueChange={(e) => setPeso(e.value)}
                        min={0}
                        minFractionDigits={2}
                        suffix=' Kg'
                        showButtons
                        buttonLayout='horizontal'
                        step={0.1}
                        locale={'pt-PT'}
                      />
                    </div>
                  )}
                  {grupoInfo.campanha.pedir_altura && (
                    <div className='flex flex-column gap-2 '>
                      <div className={' font-bold'}>Altura(metros):</div>
                      <InputNumber
                        className={'w-4rem'}
                        value={altura}
                        onValueChange={(e) => setAltura(e.value)}
                        min={0.4}
                        suffix=' metros'
                        showButtons
                        buttonLayout='horizontal'
                        step={0.1}
                        minFractionDigits={2}
                        locale={'pt-PT'}
                      />
                    </div>
                  )}
                  {grupoInfo.campanha.pedir_calcado && (
                    <div className='flex flex-column gap-2'>
                      <div className={' font-bold'}>Número Calçado:</div>
                      <InputNumber
                        className={'w-4rem'}
                        value={calcado}
                        onValueChange={(e) => setCalcado(e.value)}
                        min={10}
                        max={50}
                        showButtons
                        buttonLayout='horizontal'
                        step={1}
                        locale={'pt-PT'}
                      />
                    </div>
                  )}
                  {(grupoInfo.campanha.pedir_tshirt ||
                    grupoInfo.campanha.pedir_peso ||
                    grupoInfo.campanha.pedir_altura ||
                    grupoInfo.campanha.pedir_calcado) && <Divider></Divider>}
                  <Divider></Divider>
                  <div className='flex flex-column'>
                    <p className={'font-bold text-xl'}>
                      Condições Gerais de Venda e Política de Privacidade
                    </p>
                    {grupoInfo.campanha.documentos_condicoes.map((doc) => (
                      <Link
                        className={'m-1'}
                        key={doc.titulo}
                        to={doc.file}
                        target={'_blank'}
                        download>
                        🔗 {doc.titulo}
                      </Link>
                    ))}

                    <div className='flex flex-row m-3 justify-content-end'>
                      <Checkbox
                        id={'aceitou_condicoes_de_privacidade'}
                        checked={aceitouCond}
                        onChange={(e) => {
                          setAceitouCond(e.target.checked);
                        }}></Checkbox>
                      <label
                        htmlFor='ingredient1'
                        className='ml-2'>
                        LI NA ÍNTEGRA E ACEITO AS CONDIÇÕES GERAIS DE VENDA
                      </label>
                    </div>
                  </div>
                  <Button
                    outlined
                    tooltip={msg}
                    disabled={
                      !aceitouCond ||
                      !pacote ||
                      (!seguro.base && !aceitouCondSeguro) ||
                      (grupoInfo.campanha.pedir_tshirt && tshirt === null) ||
                      (grupoInfo.campanha.pedir_peso && peso === null) ||
                      (grupoInfo.campanha.pedir_altura && altura === null) ||
                      (grupoInfo.campanha.pedir_calcado && calcado === null)
                    }
                    label={'Comprar'}
                    onClick={() => {
                      submeterCompra();
                    }}></Button>
                  <i className={'mt-1 ml-auto text-sm'}>{msg}</i>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
