import { useFormik } from 'formik';
import {
  ClienteRequest,
  DocumentosCondicoesGeraisViagem,
  DocumentosPrivacidade,
  GeneroSexualEnum,
  TipoIdEnum,
  UtilizadorService,
} from '../slidein_api';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';
import { getFormErrorMessage, isFormFieldValid } from '../helpers';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Password } from 'primereact/password';
import { useAuth } from '../user-auth/SlideInAuthContext';
import { Checkbox } from 'primereact/checkbox';
import FullPageLoader from '../layout/FullPageLoader';
import PhoneInput from 'react-phone-input-2';
import pt from 'react-phone-input-2/lang/pt.json';
import { LayoutContext } from '../layout/context/LayoutContext';
import { MessageContext } from '../layout/context/MessageContext';
import { AutoComplete } from 'primereact/autocomplete';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';

const PAISES = [
  'Antígua e Barbuda',
  'Argentina',
  'Bahamas',
  'Barbados',
  'Belize',
  'Bolívia',
  'Brasil',
  'Chile',
  'Colômbia',
  'Costa Rica',
  'Cuba',
  'Dominica',
  'Equador',
  'El Salvador',
  'Granada',
  'Guatemala',
  'Guiana',
  'Guiana Francesa',
  'Haiti',
  'Honduras',
  'Jamaica',
  'México',
  'Nicarágua',
  'Panamá',
  'Paraguai',
  'Peru',
  'Porto Rico',
  'República Dominicana',
  'Suriname',
  'Trinidad e Tobago',
  'Uruguai',
  'Venezuela',
  'Alemanha',
  'Áustria',
  'Bélgica',
  'Croácia',
  'Dinamarca',
  'Eslováquia',
  'Eslovênia',
  'Espanha',
  'França',
  'Grécia',
  'Hungria',
  'Irlanda',
  'Itália',
  'Noruega',
  'Países Baixos',
  'Polônia',
  'Portugal',
  'Reino Unido',
  'Inglaterra',
  'País de Gales',
  'Escócia',
  'Romênia',
  'Rússia',
  'Sérvio',
  'Suécia',
  'Suíça',
  'Turquia',
  'Ucrânia',
  'Estados Unidos',
  'Canadá',
  'Angola',
  'Moçambique',
  'África do Sul',
  'Zimbabue',
  'Argélia',
  'Comores',
  'Egito',
  'Líbia',
  'Marrocos',
  'Gana',
  'Quênia',
  'Ruanda',
  'Uganda',
  'Botsuana',
  'Costa do Marfim',
  'Camarões',
  'Nigéria',
  'Somália',
  'Austrália',
  'Nova Zelândia',
  'Afeganistão',
  'Arábia Saudita',
  'Armênia',
  'Armeno',
  'China',
  'Coréia do Sul',
  'Índia',
  'Indonésia',
  'Iraque',
  'Irão',
  'Israel',
  'Japão',
  'Malásia',
  'Nepal',
  'Omã',
  'Paquistão',
  'Palestina',
  'Qatar',
  'Síria',
  'Sri Lanka',
  'Tailândia',
  'Timor Leste',
  'Emirados Árabes Unidos',
  'Vietname',
  'Iêmen',
];

const NACIO = [
  'Antiguano',
  'Argentino',
  'Bahamense',
  'Barbadiano',
  'Belizenho',
  'Boliviano',
  'Brasileiro',
  'Chileno',
  'Colombiano',
  'Costarriquenho',
  'Cubano',
  'Dominicano',
  'Equatoriano',
  'Salvadorenho',
  'Granadino',
  'Guatemalteco',
  'Guianês',
  'Guianense',
  'Haitiano',
  'Hondurenho',
  'Jamaicano',
  'Mexicano',
  'Nicaraguense',
  'Panamiano',
  'Paraguaio',
  'Peruano',
  'Porto Riquenho',
  'Dominicana',
  'cristovense',
  'vicentino',
  'lucense',
  'Surinamês',
  'Trindadense',
  'Uruguaio',
  'Venezuelano',
  'Alemão',
  'Austríaco',
  'Belga',
  'Croata',
  'Dinamarquês',
  'Eslovaco',
  'Esloveno',
  'Espanhol',
  'Francês',
  'Grego',
  'Húngaro',
  'Irlandês',
  'Italiano',
  'Noruego',
  'Holandês',
  'Polaco',
  'Português',
  'Britânico',
  'Inglês',
  'Galês',
  'Escocês',
  'Romeno',
  'Russo',
  'Sérvio',
  'Sueco',
  'Suíço',
  'Turco',
  'Ucraniano',
  'Americano',
  'Canadense',
  'Angolano',
  'Moçambicano',
  'africano',
  'Zimbabuense',
  'Argélia',
  'Comorense',
  'Egípcio',
  'Líbio',
  'Marroquino',
  'Ganés',
  'Queniano',
  'Ruandês',
  'Ugandense',
  'Bechuano',
  'Marfinense',
  'Camaronense',
  'Nigeriano',
  'Somali',
  'Australiano',
  'Neozelandês',
  'Afegão',
  'Saudita',
  'Armeno',
  'Bangladesh',
  'Chinês',
  'Coreano',
  'Indiano',
  'Indonésio',
  'Iraquiano',
  'Iraniano',
  'Israelita',
  'Japonês',
  'Malaio',
  'Nepalês',
  'Omanense',
  'Paquistanês',
  'Palestino',
  'Qatarense',
  'Sírio',
  'Cingalês',
  'Tailandês',
  'Timorense',
  'Vietnamita',
  'Iemenita',
];

const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) {
        return;
      }
      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

const ScrollToFieldError = (props: { submitCount; isValid; errors }) => {
  useEffect(() => {
    const fieldErrorNames = getFieldErrorNames(props.errors);
    if (fieldErrorNames.length <= 0) {
      return;
    }

    const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
    if (!element) {
      return;
    }

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.submitCount]);

  return null;
};

export function PerfilCliente({ modoEdit }: { modoEdit: boolean }) {
  const [loading, setLoading] = useState<boolean>(false);
  const { layoutConfig } = useContext(LayoutContext);
  const { login, user, setUser } = useAuth();
  const { msgWarn } = useContext(MessageContext);
  const [nacio, setNacio] = useState<string[]>(NACIO);
  const [paises, setPaises] = useState<string[]>(PAISES);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ddate = new Date();
  ddate.setFullYear(ddate.getFullYear() - 17);

  const [idade, setIdade] = useState<number | undefined>(undefined);
  const [otherErrors, setOtherErrors] = useState(undefined);
  const valSchema = Yup.object({
    nome_proprio: Yup.string()
      .max(50, 'Tamanho máximo 50 caracteres')
      .min(2, 'Tamanho mínimo 2 caracteres')
      .required('Obrigatório'),
    apelido: Yup.string()
      .max(50, 'Tamanho máximo 50 caracteres')
      .min(2, 'Tamanho mínimo 2 caracteres')
      .required('Obrigatório'),
    data_nascimento: Yup.string().required('Obrigatório'),
    genero_sexual: Yup.string().required('Obrigatório'),
    contacto_telemovel: Yup.string()
      .max(15, 'Tamanho máximo 15 caracteres')
      .min(6, 'Tamanho mínimo 6 caracteres')
      .required('Obrigatório'),
    email: Yup.string().email('Email Inválido').required('Obrigatório'),
    identificacao: Yup.string()
      .max(30, 'Tamanho máximo 30 caracteres')
      .min(5, 'Tamanho mínimo 5 caracteres')
      .required('Obrigatório'),
    tipo_id: Yup.string().required('Obrigatório'),
    data_emissao: Yup.date()
      .nullable()
      .when('tipo_id', {
        is: TipoIdEnum.PASSAPORTE,
        then: (schema) => schema.required('Obrigatório'),
      }),
    id_emissor: Yup.string().when('tipo_id', {
      is: TipoIdEnum.PASSAPORTE,
      then: (schema) => schema.required('Obrigatório'),
    }),
    validade_identificacao: Yup.string().required('Obrigatório'),
    nacionalidade: Yup.string().min(2, 'Tamanho mínimo 2 caracteres').required('Obrigatório'),
    contacto_casa: Yup.string()
      .max(15, 'Tamanho máximo 15 caracteres')
      .min(6, 'Tamanho mínimo 6 caracteres'),
    nome_contacto_urgencia: Yup.string()
      .max(100, 'Tamanho máximo 100 caracteres')
      .min(2, 'Tamanho mínimo 2 caracteres'),
    contacto_urgencia: Yup.string()
      .max(12, 'Tamanho máximo 12 caracteres')
      .min(6, 'Tamanho mínimo 6 caracteres'),
    email_contacto_urgencia: Yup.string().email('Email Inválido'),
    morada_rua: Yup.string()
      .max(200, 'Tamanho máximo 200 caracteres')
      .min(5, 'Tamanho mínimo 5 caracteres')
      .required('Obrigatório'),
    morada_porta: Yup.string().max(30).required('Obrigatório'),
    morada_codigo_postal: Yup.string()
      .matches(/^\d\d\d\d-\d\d\d$/, 'Inválido')
      .required('Obrigatório'),
    morada_localidade: Yup.string().max(200).required('Obrigatório'),
    morada_pais: Yup.string()
      .max(200)
      .min(2, 'Tamanho mínimo 5 caracteres')
      .required('Obrigatório'),
    notas_cliente: Yup.string().max(2024, 'Tamanho máximo atingido!'),
  });
  const kidSchema = Yup.object({
    nome_mae: Yup.string()
      .max(100, 'Tamanho máximo 100 caracteres')
      .min(2, 'Tamanho mínimo 2 caracteres')
      .required('Obrigatório'),
    nome_pai: Yup.string()
      .max(100, 'Tamanho máximo 100 caracteres')
      .min(2, 'Tamanho mínimo 2 caracteres')
      .required('Obrigatório'),
    email_pais: Yup.string().email('Email Inválido').required('Obrigatório'),
    contacto_casa: Yup.string()
      .max(15, 'Tamanho máximo 15 caracteres')
      .min(6, 'Tamanho mínimo 6 caracteres'),
    nome_contacto_urgencia: Yup.string()
      .max(100, 'Tamanho máximo 100 caracteres')
      .min(2, 'Tamanho mínimo 2 caracteres')
      .required('Obrigatório'),
    email_contacto_urgencia: Yup.string().email('Email Inválido').required('Obrigatório'),
    contacto_urgencia: Yup.string()
      .max(12, 'Tamanho máximo 12 caracteres')
      .min(6, 'Tamanho mínimo 6 caracteres')
      .required('Obrigatório'),
  });
  const passSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Tamanho mínimo 8 caracteres')
      .max(20, 'Tamanho máximo 20 caracteres')
      .required('Obrigatório'),
    re_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords não são iguais!')
      .min(8, 'Tamanho mínimo 8 caracteres')
      .max(20, 'Tamanho máximo 20 caracteres')
      .required('Obrigatório'),
  });
  const [theSchema, setTheSchema] = useState(valSchema);

  const [docsPriv, setDocsPriv] = useState<DocumentosPrivacidade[]>([]);
  const [docsCond, setDocsCond] = useState<DocumentosCondicoesGeraisViagem[]>([]);
  useEffect(() => {
    UtilizadorService.utilizadorPrivacidadeList().then(
      (value) => setDocsPriv(value),
      () => {
        navigate('/');
        msgWarn(
          'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
          true
        );
      }
    );
    UtilizadorService.utilizadorCondgeraisList().then(
      (value) => setDocsCond(value),
      () => {
        navigate('/');
        msgWarn(
          'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
          true
        );
      }
    );
  }, []);

  useEffect(() => {
    if (idade && idade < 20) {
      setTheSchema(valSchema.concat(kidSchema));
    } else {
      setTheSchema(valSchema);
    }
  }, [idade]);
  useEffect(() => {
    clienteForm.validateForm();
  }, [theSchema]);
  useEffect(() => {
    if (modoEdit) {
      clienteForm.setValues(user);
      setIdade(getAge(clienteForm.values.data_nascimento.toString()));
    } else {
      setTheSchema(valSchema.concat(passSchema));
    }
  }, [user]);

  const clienteForm = useFormik<ClienteRequest>({
    initialValues: modoEdit
      ? user
      : {
          email: '',
          nome_proprio: '',
          apelido: '',
          data_nascimento: null,
          genero_sexual: null,
          identificacao: '',
          tipo_id: TipoIdEnum.CART_O_CIDAD_O,
          validade_identificacao: null,
          data_emissao:null,
        id_emissor:'',
          nacionalidade: '',
          contacto_telemovel: '',
          contacto_casa: '',
          contacto_urgencia: '',
          nome_contacto_urgencia: '',
          email_contacto_urgencia: '',
          nome_pai: '',
          nome_mae: '',
          email_pais: '',
          morada_rua: '',
          morada_porta: '',
          morada_localidade: '',
          morada_pais: '',
          re_password: '',
          password: '',
          aceitou_condicoes_de_privacidade: false,
        },
    onSubmit: async (cliente: ClienteRequest) => {
      setLoading(true);
      const nascimento = new Date(cliente.data_nascimento);
      cliente.data_nascimento = `${nascimento.getFullYear()}-${(nascimento.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nascimento.getDate().toString().padStart(2, '0')}`;
      const validade = new Date(cliente.validade_identificacao);
      cliente.validade_identificacao = `${validade.getFullYear()}-${(validade.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${validade.getDate().toString().padStart(2, '0')}`;
      const data_emissao = new Date(cliente.data_emissao);
           cliente.data_emissao = `${data_emissao.getFullYear()}-${(data_emissao.getMonth() + 1)
             .toString()
             .padStart(2, '0')}-${data_emissao.getDate().toString().padStart(2, '0')}`;
      if (modoEdit) {
        UtilizadorService.utilizadorPartialUpdate({ id: user.id, requestBody: cliente }).then(
          (ucliente) => {
            setLoading(false);
            setUser(ucliente);
            navigate(-1);
          },
          (reason) => {
            setLoading(false);
            clienteForm.setErrors(reason.body);
            setOtherErrors(reason.body['non_field_errors']);
          }
        );
      } else {
        UtilizadorService.utilizadorCreate({ requestBody: cliente }).then(
          () => {
            login(clienteForm.values.email, clienteForm.values.password).then(
              () => {
                setLoading(false);
                const co = searchParams.get('codgrupo');
                if (co === null) {
                  navigate(`/nova_viagem`);
                } else {
                  navigate(`/nova_viagem?codgrupo=${co}`);
                }
              },
              () => {
                setLoading(false);
                navigate('/');
                msgWarn(
                  'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
                  true
                );
              }
            );
          },
          (reason) => {
            setLoading(false);
            clienteForm.setErrors(reason.body);
            setOtherErrors(reason.body['non_field_errors']);
          }
        );
      }
    },

    validationSchema: theSchema,
  });
  const getAge = (birthDateStr: string) => {
    const today = new Date();
    const birthDate = new Date(birthDateStr);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const buttonStyles: CSSProperties = {
    background: layoutConfig.colorScheme == 'dark' ? '#121212' : 'white',
    borderWidth: '1px',
    borderColor: layoutConfig.colorScheme == 'dark' ? '#383838' : '#ced4da',
  };
  const boxStyles: CSSProperties = {
    width: '100%',
    background: layoutConfig.colorScheme == 'dark' ? '#121212' : 'white',
    borderWidth: '1px',
    borderColor: layoutConfig.colorScheme == 'dark' ? '#383838' : '#ced4da',
    boxSizing: 'border-box',
    borderRadius: '1px',
    height: 'unset',
    lineHeight: 'unset',
  };

  const searchNacio = (event) => {
    let _filtered;

    if (!event.query.trim().length) {
      _filtered = [...NACIO];
    } else {
      _filtered = NACIO.filter((country) => {
        return country.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    setNacio(_filtered);
  };
  const searchPaises = (event) => {
    let _filtered;

    if (!event.query.trim().length) {
      _filtered = [...PAISES];
    } else {
      _filtered = PAISES.filter((country) => {
        return country.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    setPaises(_filtered);
  };
  if (clienteForm.values === undefined || (modoEdit && !user) || loading) {
    return <FullPageLoader />;
  }
  return (
    <div className='flex align-items-center justify-content-center '>
      <Card
        title={modoEdit ? 'Editar Perfil' : 'Bem-Vindo'}
        className='m-auto'>
        <form
          className={'p-fluid'}
          onSubmit={clienteForm.handleSubmit}>
          <ScrollToFieldError
            isValid={clienteForm.isValid && !clienteForm.errors}
            errors={clienteForm.errors}
            submitCount={clienteForm.submitCount}
          />
          <h4>Credenciais</h4>
          <div className='field'>
            <label
              htmlFor='email'
              className={classNames({
                'p-error': isFormFieldValid<ClienteRequest>('email', clienteForm),
              })}>
              Email*
            </label>
            <InputText
              id='email'
              disabled={modoEdit}
              name='email'
              type={'email'}
              autoComplete={'email'}
              value={clienteForm.values.email || ''}
              onChange={clienteForm.handleChange}
              className={classNames({
                'p-invalid': isFormFieldValid<ClienteRequest>('email', clienteForm),
              })}
            />

            {getFormErrorMessage<ClienteRequest>('email', clienteForm)}
          </div>
          <div className='grid'>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='password'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('password', clienteForm),
                })}>
                Password*
              </label>
              <Password
                id='password'
                name='password'
                autoComplete={'new-password'}
                toggleMask
                feedback={true}
                value={clienteForm.values.password || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('password', clienteForm),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('password', clienteForm)}
            </div>
            <div className='field col-12 sm:col-6 '>
              <label
                htmlFor='re_password'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('re_password', clienteForm),
                })}>
                Repetir Password*
              </label>
              <Password
                id='re_password'
                name='re_password'
                autoComplete={'new-password'}
                toggleMask
                feedback={false}
                value={clienteForm.values.re_password || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('re_password', clienteForm),
                })}
              />

              {getFormErrorMessage<ClienteRequest>('re_password', clienteForm)}
            </div>
          </div>
          {modoEdit && (
            <div className={'font-italic'}>Deixar em branco para manter a password.</div>
          )}
          {otherErrors && <small className='has-text-danger'>{otherErrors}</small>}
          <h4>Informação Pessoal</h4>
          <div className='grid'>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='nome_proprio'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('nome_proprio', clienteForm),
                })}>
                Nomes Próprios*
              </label>
              <InputText
                id='nome_proprio'
                name='nome_proprio'
                disabled={modoEdit}
                autoComplete={'given-name'}
                value={clienteForm.values.nome_proprio || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('nome_proprio', clienteForm),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('nome_proprio', clienteForm)}
            </div>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='apelido'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('apelido', clienteForm),
                })}>
                Apelidos*
              </label>
              <InputText
                id='apelido'
                name='apelido'
                disabled={modoEdit}
                autoComplete={'family-name'}
                value={clienteForm.values.apelido || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('apelido', clienteForm),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('apelido', clienteForm)}
            </div>
          </div>
          <div className='grid '>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='genero_sexual'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('genero_sexual', clienteForm),
                })}>
                Género*
              </label>
              <Dropdown
                id='genero_sexual'
                name='genero_sexual'
                autoComplete={'sex'}
                optionLabel='label'
                optionValue='value'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('genero_sexual', clienteForm),
                })}
                value={clienteForm.values.genero_sexual || ''}
                options={Object.keys(GeneroSexualEnum).map((x) => {
                  return {
                    label: GeneroSexualEnum[x as keyof typeof GeneroSexualEnum],
                    value: GeneroSexualEnum[x as keyof typeof GeneroSexualEnum],
                  };
                })}
                onChange={clienteForm.handleChange}
                placeholder='Escolher'
              />
              {getFormErrorMessage<ClienteRequest>('genero_sexual', clienteForm)}
            </div>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='data_nascimento'
                className={classNames('w-full', {
                  'p-error': isFormFieldValid<ClienteRequest>('data_nascimento', clienteForm),
                })}>
                <div className={'flex flex-row justify-content-between'}>
                  <div>Data de Nascimento*</div>
                  {idade && (
                    <span className={'text-xl font-bold text-color'}>(Idade:{idade} Anos)</span>
                  )}
                </div>
              </label>
              <Calendar
                touchUI
                id='data_nascimento'
                name='data_nascimento'
                onChange={(event) => {
                  setIdade(getAge(event.value.toString()));
                  clienteForm.handleChange(event);
                }}
                viewDate={ddate}
                value={clienteForm.values.data_nascimento || ''}
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('data_nascimento', clienteForm),
                })}
                keepInvalid={true}
                dateFormat='dd/mm/yy'
                showIcon={true}
                showButtonBar={false}></Calendar>
              {getFormErrorMessage<ClienteRequest>('data_nascimento', clienteForm)}
            </div>
          </div>
          <Divider />
          <div className='grid'>
            <div className='field col-12 sm:col-5'>
              <label
                htmlFor='tipo_id'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('tipo_id', clienteForm),
                })}>
                Tipo de Documento de Identificação*
              </label>

              <Dropdown
                id='tipo_id'
                name='tipo_id'
                optionLabel='label'
                optionValue='value'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('tipo_id', clienteForm),
                })}
                value={clienteForm.values.tipo_id || ''}
                options={Object.keys(TipoIdEnum).map((x) => {
                  return {
                    label: TipoIdEnum[x as keyof typeof TipoIdEnum],
                    value: TipoIdEnum[x as keyof typeof TipoIdEnum],
                  };
                })}
                onChange={clienteForm.handleChange}
                placeholder='Escolher'
              />
              <i className='m-1'>
                *Obrigatório Passaporte para viagens fora da União Europeia (Espaço Schengen).
              </i>
              {getFormErrorMessage<ClienteRequest>('tipo_id', clienteForm)}
            </div>
          </div>
          <div className='grid'>
            <div className='field col-12 sm:col-4'>
              <label
                htmlFor='identificacao'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('identificacao', clienteForm),
                })}>
                Número Doc. de Identificação*
              </label>
              <InputText
                id='identificacao'
                name='identificacao'
                disabled={modoEdit}
                value={clienteForm.values.identificacao || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('identificacao', clienteForm),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('identificacao', clienteForm)}
            </div>
            <div className='field col-12 sm:col-4'>
              <label
                htmlFor='validade_identificacao'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>(
                    'validade_identificacao',
                    clienteForm
                  ),
                })}>
                Validade do Doc. Identificação*
              </label>

              <Calendar
                touchUI
                id='validade_identificacao'
                name='validade_identificacao'
                onChange={(event) => {
                  clienteForm.handleChange(event);
                }}
                locale={'pt'}
                minDate={new Date()}
                value={clienteForm.values.validade_identificacao || ''}
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>(
                    'validade_identificacao',
                    clienteForm
                  ),
                })}
                keepInvalid={true}
                dateFormat='dd/mm/yy'
                showIcon={true}
                showButtonBar={false}></Calendar>
              {getFormErrorMessage<ClienteRequest>('validade_identificacao', clienteForm)}
            </div>
            <div className='field col-12 sm:col-4'>
              <label
                htmlFor='nacionalidade'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('nacionalidade', clienteForm),
                })}>
                Nacionalidade*
              </label>
              <AutoComplete
                id='nacionalidade'
                name='nacionalidade'
                suggestions={nacio}
                completeMethod={searchNacio}
                value={clienteForm.values.nacionalidade || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('nacionalidade', clienteForm),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('nacionalidade', clienteForm)}
            </div>
          </div>
          {clienteForm.values.tipo_id === TipoIdEnum.PASSAPORTE && (
            <div className='grid'>
              <div className='field col-12 sm:col-6'>
                <label
                  htmlFor='id_emissor'
                  className={classNames({
                    'p-error': isFormFieldValid<ClienteRequest>('id_emissor', clienteForm),
                  })}>
                  Doc. Identificação emitido por:*
                </label>
                <InputText
                  id='id_emissor'
                  name='id_emissor'
                  value={clienteForm.values.id_emissor || ''}
                  onChange={clienteForm.handleChange}
                  className={classNames({
                    'p-invalid': isFormFieldValid<ClienteRequest>('id_emissor', clienteForm),
                  })}
                />
                {getFormErrorMessage<ClienteRequest>('id_emissor', clienteForm)}
              </div>
              <div className='field col-12 sm:col-6'>
                <label
                  htmlFor='data_emissao'
                  className={classNames('w-full', {
                    'p-error': isFormFieldValid<ClienteRequest>('data_emissao', clienteForm),
                  })}>
                  <div className={'flex flex-row justify-content-between'}>
                    <div>Data de Emissão*</div>
                  </div>
                </label>
                <Calendar
                  touchUI
                  id='data_emissao'
                  name='data_emissao'
                  onChange={(event) => {
                    clienteForm.handleChange(event);
                  }}
                  value={clienteForm.values.data_emissao || ''}
                  className={classNames({
                    'p-error': isFormFieldValid<ClienteRequest>('data_emissao', clienteForm),
                  })}
                  keepInvalid={true}
                  dateFormat='dd/mm/yy'
                  showIcon={true}
                  showButtonBar={false}></Calendar>
                {getFormErrorMessage<ClienteRequest>('data_emissao', clienteForm)}
              </div>
            </div>
          )}
          <Divider />
          <div className='grid'>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='contacto_telemovel'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('contacto_telemovel', clienteForm),
                })}>
                Contacto Telémovel*
              </label>
              <PhoneInput
                inputProps={{
                  id: 'contacto_telemovel',
                  name: 'contacto_telemovel',
                  autoComplete: 'tel',
                }}
                disabled={modoEdit}
                country={'pt'}
                countryCodeEditable={false}
                localization={pt}
                inputClass={classNames('p-inputtext p-component', {
                  'p-invalid': isFormFieldValid<ClienteRequest>('contacto_telemovel', clienteForm),
                })}
                inputStyle={boxStyles}
                buttonStyle={buttonStyles}
                dropdownStyle={buttonStyles}
                containerStyle={boxStyles}
                searchStyle={buttonStyles}
                value={clienteForm.values.contacto_telemovel || ''}
                onChange={(val) => clienteForm.setFieldValue('contacto_telemovel', val)}
              />
              {getFormErrorMessage<ClienteRequest>('contacto_telemovel', clienteForm)}
            </div>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='contacto_casa'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('contacto_casa', clienteForm),
                })}>
                Contacto Telefone Casa
              </label>
              <div className={'flex flex-row'}>
                <PhoneInput
                  inputProps={{
                    id: 'contacto_casa',
                    name: 'contacto_casa',
                    autoComplete: 'tel',
                  }}
                  country={'pt'}
                  countryCodeEditable={false}
                  localization={pt}
                  inputClass={classNames('p-inputtext p-component', {
                    'p-invalid': isFormFieldValid<ClienteRequest>('contacto_casa', clienteForm),
                  })}
                  inputStyle={boxStyles}
                  buttonStyle={buttonStyles}
                  dropdownStyle={buttonStyles}
                  containerStyle={boxStyles}
                  searchStyle={buttonStyles}
                  value={clienteForm.values.contacto_casa || ''}
                  onChange={(val) => clienteForm.setFieldValue('contacto_casa', val)}
                />
              </div>
              {getFormErrorMessage<ClienteRequest>('contacto_casa', clienteForm)}
            </div>
          </div>
          <h4>Morada</h4>
          <div className='grid'>
            <div className='field col-12  sm:col-8'>
              <label
                htmlFor='morada_porta'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('morada_rua', clienteForm),
                })}>
                Rua/Av/Trav...*
              </label>
              <InputText
                id='morada_rua'
                name='morada_rua'
                autoComplete={'street-address'}
                value={clienteForm.values.morada_rua || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('morada_rua', clienteForm),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('morada_rua', clienteForm)}
            </div>
            <div className='field col-12 sm:col-4'>
              <label
                htmlFor='morada_porta'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('morada_porta', clienteForm),
                })}>
                Porta/andar*
              </label>
              <InputText
                id='morada_porta'
                name='morada_porta'
                value={clienteForm.values.morada_porta || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('morada_porta', clienteForm),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('morada_porta', clienteForm)}
            </div>

            <div className='field col-12 sm:col-4'>
              <label
                htmlFor='morada_codigo_postal'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('morada_codigo_postal', clienteForm),
                })}>
                Código Postal*
              </label>
              <InputText
                id='morada_codigo_postal'
                name='morada_codigo_postal'
                autoComplete={'postal-code'}
                value={clienteForm.values.morada_codigo_postal || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>(
                    'morada_codigo_postal',
                    clienteForm
                  ),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('morada_codigo_postal', clienteForm)}
            </div>
            <div className='field col-12 sm:col-4'>
              <label
                htmlFor='morada_localidade'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('morada_localidade', clienteForm),
                })}>
                Localidade*
              </label>
              <InputText
                id='morada_localidade'
                name='morada_localidade'
                value={clienteForm.values.morada_localidade || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('morada_localidade', clienteForm),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('morada_localidade', clienteForm)}
            </div>
            <div className='field col-12 sm:col-4'>
              <label
                htmlFor='morada_pais'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('morada_pais', clienteForm),
                })}>
                País*
              </label>
              <AutoComplete
                id='morada_pais'
                name='morada_pais'
                suggestions={paises}
                completeMethod={searchPaises}
                // autoComplete={'country-name'}
                value={clienteForm.values.morada_pais || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>('morada_pais', clienteForm),
                })}
              />
              <datalist id='paiseslist'>
                {PAISES.map((na) => (
                  <option
                    key={na}
                    value={na}></option>
                ))}
              </datalist>
              {getFormErrorMessage<ClienteRequest>('morada_pais', clienteForm)}
            </div>
          </div>
          <h4>Contactos Urgência</h4>
          <div className='grid'>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='contacto_urgencia'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>('contacto_urgencia', clienteForm),
                })}>
                Nrº de telefone do contacto de urgência{idade && idade < 21 ? '*' : ''}
              </label>
              <div className={'flex flex-row'}>
                <PhoneInput
                  inputProps={{
                    id: 'contacto_urgencia',
                    name: 'contacto_urgencia',
                    autoComplete: 'tel',
                  }}
                  country={'pt'}
                  countryCodeEditable={false}
                  localization={pt}
                  inputClass={classNames('p-inputtext p-component', {
                    'p-invalid': isFormFieldValid<ClienteRequest>('contacto_urgencia', clienteForm),
                  })}
                  inputStyle={boxStyles}
                  buttonStyle={buttonStyles}
                  dropdownStyle={buttonStyles}
                  containerStyle={boxStyles}
                  searchStyle={buttonStyles}
                  value={clienteForm.values.contacto_urgencia || ''}
                  onChange={(val) => clienteForm.setFieldValue('contacto_urgencia', val)}
                />
              </div>
              {getFormErrorMessage<ClienteRequest>('contacto_urgencia', clienteForm)}
            </div>
            <div className='field col-12 sm:col-6'>
              <label
                htmlFor='nome_contacto_urgencia'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>(
                    'nome_contacto_urgencia',
                    clienteForm
                  ),
                })}>
                Nome do contacto de urgência{idade && idade < 20 ? '*' : ''}
              </label>
              <InputText
                id='nome_contacto_urgencia'
                name='nome_contacto_urgencia'
                value={clienteForm.values.nome_contacto_urgencia || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>(
                    'nome_contacto_urgencia',
                    clienteForm
                  ),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('nome_contacto_urgencia', clienteForm)}
            </div>
            <div className='field col-12'>
              <label
                htmlFor='email_contacto_urgencia'
                className={classNames({
                  'p-error': isFormFieldValid<ClienteRequest>(
                    'email_contacto_urgencia',
                    clienteForm
                  ),
                })}>
                Email contacto de urgência{idade && idade < 20 ? '*' : ''}
              </label>
              <InputText
                id='email_contacto_urgencia'
                name='email_contacto_urgencia'
                type={'email'}
                value={clienteForm.values.email_contacto_urgencia || ''}
                onChange={clienteForm.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<ClienteRequest>(
                    'email_contacto_urgencia',
                    clienteForm
                  ),
                })}
              />
              {getFormErrorMessage<ClienteRequest>('email_contacto_urgencia', clienteForm)}
            </div>
          </div>
          {idade && idade < 20 && (
            <>
              <div className='field flex-grow-1'>
                <label
                  htmlFor='nome_pai'
                  className={classNames({
                    'p-error': isFormFieldValid<ClienteRequest>('nome_pai', clienteForm),
                  })}>
                  Nome completo do Pai*
                </label>
                <InputText
                  id='nome_pai'
                  name='nome_pai'
                  autoComplete={'off'}
                  value={clienteForm.values.nome_pai || ''}
                  onChange={clienteForm.handleChange}
                  className={classNames({
                    'p-invalid': isFormFieldValid<ClienteRequest>('nome_pai', clienteForm),
                  })}
                />
                {getFormErrorMessage<ClienteRequest>('nome_pai', clienteForm)}
              </div>
              <div className='field flex-grow-1'>
                <label
                  htmlFor='nome_mae'
                  className={classNames({
                    'p-error': isFormFieldValid<ClienteRequest>('nome_mae', clienteForm),
                  })}>
                  Nome completo da Mãe*
                </label>
                <InputText
                  id='nome_mae'
                  name='nome_mae'
                  autoComplete={'off'}
                  value={clienteForm.values.nome_mae || ''}
                  onChange={clienteForm.handleChange}
                  className={classNames({
                    'p-invalid': isFormFieldValid<ClienteRequest>('nome_mae', clienteForm),
                  })}
                />
                {getFormErrorMessage<ClienteRequest>('nome_mae', clienteForm)}
              </div>
              <div className='field'>
                <label
                  htmlFor='email_pais'
                  className={classNames({
                    'p-error': isFormFieldValid<ClienteRequest>('email_pais', clienteForm),
                  })}>
                  Email Pai/Mãe*
                </label>
                <InputText
                  id='email_pais'
                  name='email_pais'
                  type={'email'}
                  value={clienteForm.values.email_pais || ''}
                  onChange={clienteForm.handleChange}
                  className={classNames({
                    'p-invalid': isFormFieldValid<ClienteRequest>('email_pais', clienteForm),
                  })}
                />
                {getFormErrorMessage<ClienteRequest>('email_pais', clienteForm)}
              </div>
            </>
          )}{' '}
          <div className='field'>
            <label>Notas importantes (Doenças, Alergias, etc...):</label>
            <InputTextarea
              className={classNames({
                'p-invalid': isFormFieldValid<ClienteRequest>('notas_cliente', clienteForm),
              })}
              id='notas_cliente'
              name='notas_cliente'
              autoResize
              value={clienteForm.values.notas_cliente || ''}
              onChange={clienteForm.handleChange}
              rows={5}></InputTextarea>
            {getFormErrorMessage<ClienteRequest>('notas_cliente', clienteForm)}
          </div>
          <div className='flex flex-column'>
            <p className={'font-bold'}>Política de Privacidade:</p>
            {docsCond.map((doc) => (
              <Link
                className={'m-1'}
                key={doc.titulo}
                to={doc.file}
                target='_blank'
                rel='noreferrer'
                download>
                🔗 {doc.titulo}
              </Link>
            ))}
            {docsPriv.map((doc) => (
              <Link
                className={'m-1'}
                key={doc.titulo}
                target='_blank'
                rel='noreferrer'
                to={doc.file}
                download>
                🔗 {doc.titulo}
              </Link>
            ))}
            {!modoEdit && (
              <div className='flex flex-row m-3 justify-content-end'>
                <Checkbox
                  id={'aceitou_condicoes_de_privacidade'}
                  disabled={modoEdit}
                  checked={clienteForm.values.aceitou_condicoes_de_privacidade || false}
                  onChange={clienteForm.handleChange}></Checkbox>
                <label
                  htmlFor='ingredient1'
                  className='ml-2'>
                  LI NA ÍNTEGRA E ACEITO A POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS
                </label>
              </div>
            )}
          </div>
          <span className='p-buttonset'>
            <Button
              type='button'
              label='Cancelar'
              className='mt-2 p-button-warning w-1 p-button-outlined'
              onClick={() => {
                navigate(-1);
              }}
            />
            <Button
              type='submit'
              disabled={!clienteForm.values.aceitou_condicoes_de_privacidade}
              className='mt-2 w-1 p-button-outlined'
              label={modoEdit ? 'Actualizar' : 'Registar'}
            />
          </span>
        </form>
      </Card>
    </div>
  );
}
